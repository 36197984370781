@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --sky-primary: #42a4f5;
  --sky-primary-50: #e3f2fd;
  --sky-primary-100: #bbdefb;
  --sky-primary-200: #90c9f9;
  --sky-primary-300: #64b4f6;
  --sky-primary-400: var(--sky-primary);
  --sky-primary-500: #2395f3;
  --sky-primary-600: #2087e5;
  --sky-primary-700: #1c75d2;
  --sky-primary-800: #1864c0;
  --sky-primary-900: #1146a0;
  --sky-primary-hover: var(--sky-primary-300);
  --sky-primary-active: var(--sky-primary-600);
  --sky-primary-gradient: linear-gradient(
    90deg,
    var(--sky-primary-600) 0%,
    var(--sky-primary-400) 100%
  );

  --blizzard-primary: #1bcdf5;
  --blizzard-primary-50: #ebfeff;
  --blizzard-primary-100: #cdf9ff;
  --blizzard-primary-200: #96efff;
  --blizzard-primary-300: #62e4fe;
  --blizzard-primary-400: var(--blizzard-primary);
  --blizzard-primary-500: #00b0db;
  --blizzard-primary-600: #028cb8;
  --blizzard-primary-700: #0a7094;
  --blizzard-primary-800: #125b78;
  --blizzard-primary-900: #134b66;
  --blizzard-primary-hover: var(--blizzard-primary-300);
  --blizzard-primary-active: var(--blizzard-primary-600);
  --blizzard-primary-gradient: linear-gradient(
    90deg,
    var(--blizzard-primary-600) 0%,
    var(--blizzard-primary-400) 100%
  );
}

body {
  margin: 0;
  font-family: "THSarabunPSK";
  /* font-family: "THSarabunNew"; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

svg > text.highcharts-credits {
  display: none;
}

.leaflet-control {
  z-index: 0 !important;
}
.leaflet-pane {
  z-index: 0 !important;
}
.leaflet-top,
.leaflet-bottom {
  z-index: 0 !important;
}

.leaflet-container {
  width: 100wh;
  height: 100vh;
}

div[role="tablist"] {
  input[type="radio"]:first-child {
    margin-left: 32px;
  }

  input[type="radio"] {
    border-start-start-radius: 0px;
    border-start-end-radius: 0px;
  }

  input[type="radio"].tab:is(input:checked):before {
    background-image: none;
  }
}
